<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <b-container>
    <b-row>
      <!-- <b-col
        v-if="currentBreakPoint !== 'xs'"
        class="d-flex justify-content-center align-items-center order-sm-2 order-md-1"
        sm="12"
        md="4"
      >
        <b-img
          width="400px"
          :src="require('@/assets/images/pages/coming-soon.svg')"
        />
      </b-col> -->
      <b-col
        sm="12"
        md="12"
        class="order-sm-1 order-md-2"
      >
        <b-card>
          <b-card-title>
            {{ $t('editUser') }}
          </b-card-title>
          <validation-observer ref="formValidation">
            <b-form @submit.prevent="validateForm()">
              <b-row>
                <b-col
                  sm="12"
                  lg="6"
                >
                  <b-form-group label="Nombre">
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <b-form-input v-model="user.name" autocomplete="off" type="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  lg="6"
                >
                  <b-form-group label="Email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required"
                    >
                      <b-form-input v-model="user.email" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col sm="12">
                  <h4>{{ $t('userLogo') }}</h4>
                  <div class="d-flex">
                    <base-cropper
                      :index="'0'"
                      :model="user"
                      @cropped-image="user.logo = $event"
                    />
                    <span class="text-muted ml-1">{{
                      $t('resolutionInfo')
                    }}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row
                v-if="companyLogoValidRoles.includes(user.role_name)"
                class="mb-1"
              >
                <b-col sm="12">
                  <h4>{{ $t('companyLogo') }}</h4>
                  <div class="d-flex">
                    <base-cropper
                      :index="'1'"
                      :model="user.company_information"
                      :boundary="{ width: 300, height: 300 }"
                      :viewport="{ width: 206, height: 40, type: 'square' }"
                      @cropped-image="user.company_information.logo = $event"
                    />
                    <span class="text-muted ml-1">{{
                      $t('resolutionCompanyLogoInfo')
                    }}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col
                  sm="12"
                  class="d-flex flex-wrap"
                >
                  <div class="mr-1 mb-1 mb-md-0">
                    <h4>{{ $t('primaryColor') }}</h4>
                    <div class="color-picker-selector">
                      <color-picker
                        theme="dark"
                        :color="user.primary_color"
                        :sucker-hide="true"
                        :colors-default="[]"
                        @changeColor="changePrimaryColor"
                      />
                    </div>
                  </div>
                  <div>
                    <h4>{{ $t('secondaryColor') }}</h4>
                    <div class="color-picker-selector">
                      <color-picker
                        theme="dark"
                        :color="user.secondary_color"
                        :sucker-hide="true"
                        :colors-default="[]"
                        @changeColor="changeSecondaryColor"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                >
                  {{ $t('save') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'
import colorPicker from '@caohenghu/vue-colorpicker'
import BaseCropper from '@/components/BaseCropper.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseCropper,
    colorPicker,
  },
  data() {
    return {
      required,
      user: {
        name: '',
        email: '',
      },
    }
  },
  setup() {
    const { navbarColors, navbarBackgroundColor } = useAppCustomizer()
    return {
      navbarColors,
      navbarBackgroundColor,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('app', ['currentBreakPoint', 'isSmallScreen']),
    companyLogoValidRoles() {
      return ['supplier_admin', 'customer']
    },
  },
  created() {
    this.user = JSON.parse(JSON.stringify(this.currentUser))
    if (typeof this.user.company_information === 'string') {
      this.user = { ...this.user, company_information: { logo: null } }
    }
  },
  methods: {
    ...mapMutations('auth', {
      changeCurrentUser: 'CHANGE_CURRENT_USER',
      changeCurrentUserCompanyLogo: 'CHANGE_CURRENT_USER_COMPANY_LOGO',
    }),
    ...mapActions('auth', ['createCustomer']),
    ...mapActions('users', ['editUser']),

    changePrimaryColor(color) {
      document.documentElement.style.setProperty('--primary-custom', color.hex)
      this.user.primary_color = color.hex
    },
    changeSecondaryColor(color) {
      document.documentElement.style.setProperty(
        '--secondary-custom',
        color.hex,
      )
      this.user.secondary_color = color.hex
    },

    validateForm() {
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          if (this.user.company_information?.logo?.data) {
            this.user.company_information_attributes = this.user.company_information
          }
          this.editUser(this.user).then(response => {
            this.changeCurrentUser({ field: 'name', value: response.name })
            this.changeCurrentUser({ field: 'email', value: response.email })
            this.changeCurrentUser({ field: 'logo', value: response.logo })
            this.changeCurrentUser({
              field: 'primary_color',
              value: response.primary_color,
            })
            this.changeCurrentUser({
              field: 'secondary_color',
              value: response.secondary_color,
            })
            this.changeCurrentUserCompanyLogo(response.company_information.logo)
            localStorage.setItem('userData', JSON.stringify(this.currentUser))
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('settingsUpdated'),
                icon: 'UserIcon',
                variant: 'success',
              },
            })
            if (response.role_name === 'customer') {
              this.$router.push({
                name: 'projects',
                params: {
                  customerId: response.scoped_roles[0]?.role_resource_id,
                },
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.color-picker-selector {
  ::v-deep .color-type {
    display: none;
  }
  ::v-deep .history {
    display: none;
  }
  ::v-deep .color-show {
    display: none;
    height: 0px !important;
  }
  ::v-deep .color-alpha {
    display: none;
    height: 0px !important;
  }
}
</style>
